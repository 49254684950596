@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

html {
  color: #f7f2eb;
  background-color: #080707;
  font-size: 16px;
}

body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}