.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100svh;
  max-width: 1700px;
}

.brands {
  position: relative;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
}

.brand img {
  width: 100%;
}

.brand {
  display: flex;
  align-items: end;
  position: relative;

  /* 285px */
  max-width: 17.8125em;
  width: 50%;
  opacity: 30%;
  transition: opacity .1s ease-in;
  margin: 0 7.67%;
}

.brand:hover {
  opacity: 100%;
}

.copyright {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  
  font-size: 10px;
  margin-bottom: 31px;
}